<div
	ucImpression
	(onImpression)="onUniImpression($event)"
	class="flex items-center justify-between w-full gap-2 py-5 border-b lg:gap-3 lg:py-0 lg:flex-col lg:items-start lg:border-b-0"
>
	<div class="flex items-center w-full gap-2 lg:gap-3 lg:flex-col lg:items-start">
		<div class="block w-16 lg:w-full">
			<!-- mobile: logo -->
			<button
				(click)="openModal()"
				class="relative block w-16 h-16 cursor-pointer lg:hidden"
			>
				<img
					[ngSrc]="uni().logo | cdn"
					fill
					[attr.alt]="'Logo of ' + uni().name"
					class="object-contain border rounded-2xl"
				/>
			</button>

			<!-- desktop: cover photo-->
			<button
				(click)="openModal()"
				class="relative hidden cursor-pointer lg:w-full lg:block lg:h-44"
			>
				<img
					[ngSrc]="uni().cover_image | cdn"
					fill
					[attr.alt]="'Photo of ' + uni().name"
					class="object-cover rounded-2xl"
				/>
			</button>
		</div>

		<div class="w-full">
			<h3>
				<button
					(click)="openModal()"
					class="text-base font-bold text-left text-gray-900 lg:text-lg"
				>
					{{ uni().short_name }}
				</button>
			</h3>
			@if (uni().recommended_percentage) {
				<p class="text-sm text-gray-500">
					{{ uni().recommended_percentage }}% Recommended
				</p>
			}
			@if (!uni().recommended_percentage) {
				<uc-write-review-btn
					[universityId]="uni().id"
					[universityName]="uni().name"
					[universityUrl]="uni().slug"
					buttonText="Leave a review"
					customStyles="text-base blue-link-underline"
				/>
			}
		</div>
	</div>

	<!-- cta buttons -->
	<div
		class="flex gap-1 lg:gap-2 lg:w-full lg:grid"
		[ngClass]="{
			'lg:grid-cols-1': !uni().phone_number || uni().external_url,
			'lg:grid-cols-2': uni().phone_number && uni().external_url,
		}"
	>
		@if (uni().phone_number) {
			<uc-call-uni-btn
				[phoneNumber]="uni().phone_number"
				buttonText="Call"
				mobileButtonText=""
				class="lg:w-full"
				customStyles="!px-3 flex lg:block w-auto lg:w-full !text-sm lg:!px-0"
				[uniId]="uni().id"
				[uniName]="uni().name"
				[source]="uni().name"
				sourceType="uni"
				componentName="Prime Provider"
				[cleSearchOrderPosition]="uni().order"
			>
				<uc-call-svg class="lg:hidden" />
			</uc-call-uni-btn>
		}

		@if (uni().external_url) {
			<uc-clearing-visit-website-btn
				customStyles="!px-3 lg:block lg:w-full !text-sm lg:!px-0"
				[externalUrl]="uni().external_url"
				componentName="Prime Provider"
				[uniId]="uni().id"
				[source]="uni().name"
				[buttonText]="isMobile() ? 'Site' : 'Visit Website'"
				sourceType="uni"
				[trackingEnabled]="false"
				[uniName]="uni().name"
				[cleSearchOrderPosition]="uni().order"
				[privacyPolicyUrl]="null"
			/>

			<!-- todo-investigate -->
			<!-- @if (uni().external_url) {
				<uc-visit-website-btn
					customStyles="!px-3 lg:hidden"
					[externalUrl]="uni().external_url"
					product="clearing"
					componentName="Prime Provider"
					[uniId]="uni().id"
					[source]="uni().name"
					buttonText="Site"
					sourceType="uni"
					[trackingEnabled]="false"
					[uniName]="uni().name"
					[ugSearchOrderPosition]="null"
					[cleSearchOrderPosition]="uni().order"
				/>
			} -->
		}
	</div>
</div>
