@if (providers$ | async; as providers) {
	<div class="divider-top lg:border-b lg:pb-16">
		<uc-heading
			[headingTitle]="heading()"
			[description]="description"
			[charLimit]="110"
			[truncateMobileOnly]="true"
		/>
		<div class="gap-8 lg:grid-cols-4 lg:mt-8 lg:grid">
			@for (uni of providers; track uni) {
				<uc-prime-provider-card [uni]="uni" />
			}
		</div>
	</div>
}
