import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ImpressionDirective, UniversityGTMService } from '@uc/web/core';
import { PrimeProviderPopupComponent } from '../prime-provider-popup/prime-provider-popup.component';
import { PrimeProviderPopupService } from '../prime-provider-popup/prime-provider-popup.service';
import { PrimeProvider } from '../../models/prime-providers.models';
import { ModalService } from '@uc/shared/ui';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProductEnum, ScreenWidth } from '@uc/web/shared/data-models';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import {
	CallUniBtnComponent,
	ClearingVisitWebsiteBtnComponent,
	WriteReviewBtnComponent,
} from '@web/shared/ui';
import { CallSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-prime-provider-card',
	templateUrl: './prime-provider-card.component.html',
	standalone: true,
	imports: [
		NgClass,
		NgOptimizedImage,
		CdnPathPipe,
		ImpressionDirective,
		WriteReviewBtnComponent,
		CallUniBtnComponent,
		CallSvgComponent,
		ClearingVisitWebsiteBtnComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrimeProviderCardComponent {
	uni = input.required<PrimeProvider>();

	isMobile = toSignal(
		this._breakpointObserver.observe(`(max-width: ${ScreenWidth.SM - 1}px)`),
	);

	constructor(
		private _uniGTMSrv: UniversityGTMService,
		private _pppSrv: PrimeProviderPopupService,
		private _modalSrv: ModalService,
		private _breakpointObserver: BreakpointObserver,
	) {}

	openModal() {
		this._pppSrv.setUniversity(this.uni());
		this._modalSrv.setStyleClass('modal-panel-full');
		this._modalSrv.setContent(PrimeProviderPopupComponent);
		this._modalSrv.show();
	}

	onUniImpression(impression: any) {
		if (impression) {
			this._uniGTMSrv.uniImpression(
				this.uni().id,
				this.uni().name,
				null,
				this.uni().order,
				ProductEnum.Clearing,
			);
		}
	}
}
