import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	_STATE_PRIME_PROVIDERS_FEATURE_KEY,
	PrimeProvidersState,
} from './prime-providers.reducer';

// Lookup the 'StateRecommendedUniversities' feature state managed by NgRx
export const getStatePrimeProviders = createFeatureSelector<PrimeProvidersState>(
	_STATE_PRIME_PROVIDERS_FEATURE_KEY,
);

export const getPrimeProviders = createSelector(
	getStatePrimeProviders,
	(state: PrimeProvidersState) => state.providers,
);
