import { Injectable } from '@angular/core';
import { PrimeProvider } from '../models/prime-providers.models';
import { Apollo } from 'apollo-angular';
import { GET_CLEARING_PRIME_PROVIDERS } from './queries.graphql';
import { EMPTY, catchError, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PrimeProvidersService {
	constructor(private _apollo: Apollo) {}

	getPrimeProviders() {
		return this._apollo
			.query<{
				clearingPrimeProviders: PrimeProvider[];
			}>({
				query: GET_CLEARING_PRIME_PROVIDERS,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => data.clearingPrimeProviders),
			);
	}
}
