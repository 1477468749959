import { createAction, props } from '@ngrx/store';
import { PrimeProvider } from '../../models/prime-providers.models';

export const fetchPrimeProviders = createAction(
	'[Prime Providers/API] Fetch Prime Providers',
);

export const updatePrimeProviders = createAction(
	'[Prime Providers] Update Prime Providers',
	props<{ providers: PrimeProvider[] }>(),
);

export const errorPrimeProviders = createAction(
	'[Prime Providers] Failure',
	props<{ error: any }>(),
);
