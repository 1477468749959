import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrimeProvider } from '../../models/prime-providers.models';

@Injectable({
	providedIn: 'root',
})
export class PrimeProviderPopupService {
	private university = new BehaviorSubject<PrimeProvider>({} as PrimeProvider);
	university$ = this.university.asObservable();

	setUniversity(uni: PrimeProvider) {
		this.university.next(uni);
	}
}
