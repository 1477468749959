<article
	#article
	class="md:pt-0 relative h-max bg-white sm:rounded-lg lg:mx-auto lg:max-w-[1104px] overflow-x-auto"
>
	@if (uni$ | async; as uni) {
		<section class="lg:m-6 lg:grid lg:grid-cols-2">
			<div class="relative h-96 lg:h-[510px] sm:m-5 lg:m-0">
				<img
					[src]="uni.cover_image | cdn"
					fill
					[attr.alt]="uni.name"
					class="object-cover w-full h-full sm:rounded-2xl"
				/>
				<p
					class="absolute flex items-center text-xs z-10 font-medium rounded-full top-16 left-8 sm:top-4 sm:left-3 bg-uc-clearing-103 text-uc-clearing-104 py-0.5 px-2.5"
				>
					<uc-green-flame-svg />&nbsp;Highly Recommended
				</p>
				<div
					(click)="closeModal()"
					class="absolute z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full cursor-pointer sm:top-3 sm:right-3 lg:hidden top-16 right-5"
				>
					<uc-close-modal-svg customStyles="!w-5 !h-5" />
				</div>
				<div
					class="absolute bottom-0 left-0 grid items-center w-full grid-flow-col px-5 py-4 sm:rounded-b-2xl glass-overlay"
				>
					<div class="flex items-center">
						<div class="relative w-12 h-12 mr-3 bg-white rounded-lg">
							<img
								[ngSrc]="uni.logo | cdn"
								fill
								[attr.alt]="'Logo of ' + uni.name"
								class="object-contain rounded-lg"
							/>
						</div>
						<div class="flex flex-col">
							<!-- Mobile View Uni Name -->
							<p class="text-base font-bold leading-6 text-white sm:hidden">
								{{ uni.short_name }}
							</p>
							<!-- Tablet and Desktop View Uni Name-->
							<a
								[routerLink]="['/universities', uni.slug, 'clearing']"
								(click)="closeModal(); onUniProfileClick(uni)"
								class="hidden text-base font-bold leading-6 text-white cursor-pointer sm:inline"
							>
								{{ isDesktop ? uni.short_name : uni.name }}
							</a>
							<p class="text-sm font-normal leading-[21px] text-white">
								@if (uni.recommended_percentage > 0) {
									{{ uni.recommended_percentage }}% Recommended
								} @else {
									<uc-write-review-btn
										[universityId]="uni.id"
										[universityName]="uni.name"
										[universityUrl]="uni.slug"
										buttonText="Leave a review"
										(click)="closeModal()"
									/>
								}
							</p>
						</div>
					</div>
					<div class="justify-self-end">
						@if (uni.phone_number) {
							<uc-call-uni-btn
								[uniId]="uni.id"
								[uniName]="uni.name"
								[phoneNumber]="uni.phone_number"
								buttonText="Call University"
								[mobileButtonText]="
									'Call University' | abbrButtonText: 'Call'
								"
								componentName="Prime Popup"
								[source]="uni.name"
								sourceType="uni"
								customStyles="!py-2 !py-3 sm:!py-3 lg:!py-5"
								[cleSearchOrderPosition]="uni.order"
							/>
						}
						@if (uni.external_url && !uni.phone_number) {
							<uc-clearing-visit-website-btn
								[uniId]="uni.id"
								[externalUrl]="uni.external_url"
								[buttonText]="'Visit Website' | abbrButtonText: 'Site'"
								componentName="Prime Popup"
								[source]="uni.name"
								sourceType="uni"
								customStyles="!py-2 !py-3 sm:!py-3 lg:!py-5"
								[trackingEnabled]="false"
								[uniName]="uni.name"
								[cleSearchOrderPosition]="uni.order"
								[privacyPolicyUrl]="null"
							/>
						}
					</div>
				</div>
			</div>
			<div
				class="mx-5 border-b lg:flex lg:flex-col lg:px-5 py-7 lg:mx-0 sm:pt-0 sm:pb-5 sm:border-none"
			>
				<div
					(click)="closeModal()"
					class="absolute items-center justify-center hidden w-8 h-8 border border-gray-200 rounded-full cursor-pointer bg-gray-50 top-6 right-6 lg:flex"
				>
					<uc-close-modal-svg customStyles="!w-5 !h-5" />
				</div>
				<h3 class="mb-2 text-xl font-bold leading-8 text-black lg:mt-3">
					About {{ uni.name }}
				</h3>
				<p
					class="mb-5 text-sm text-gray-900 lg:mb-auto"
					[innerHTML]="uni.overview | ucEntities"
				></p>
				<div class="flex gap-3">
					@if (uni.external_url) {
						<uc-clearing-visit-website-btn
							class="flex-auto"
							[uniId]="uni.id"
							[externalUrl]="uni.external_url"
							componentName="Prime Popup"
							[source]="uni.name"
							sourceType="uni"
							customStyles="w-full"
							[trackingEnabled]="false"
							[uniName]="uni.name"
							[cleSearchOrderPosition]="uni.order"
							[privacyPolicyUrl]="null"
						/>
					}
					@if (uni.phone_number && !isMobile) {
						<uc-call-uni-btn
							class="flex-auto"
							[uniId]="uni.id"
							[uniName]="uni.name"
							[phoneNumber]="uni.phone_number"
							buttonText="Call University"
							mobileButtonText="Call University"
							componentName="Prime Popup"
							[source]="uni.name"
							sourceType="uni"
							customStyles="w-full"
							[cleSearchOrderPosition]="uni.order"
						/>
					}
				</div>
			</div>
			<!-- Mobile Only Section-->
			<uc-prime-provider-popup-mobile
				[uniName]="uni.name"
				[uniId]="uni.id"
				[phoneNumber]="uni.phone_number"
				[imageCover]="uni.cover_image"
				[externalUrl]="uni.external_url"
				[uniSlug]="uni.slug"
				[gallery]="uni.gallery"
				[cleSearchOrderPosition]="uni.order"
				[privacyPolicyUrl]="null"
			/>
		</section>
	}
</article>
