import { createReducer, on, Action } from '@ngrx/store';

import * as PrimeProviderActions from './prime-providers.actions';
import { PrimeProvider } from '../../models/prime-providers.models';

export const _STATE_PRIME_PROVIDERS_FEATURE_KEY = 'statePrimeProviders';

export interface PrimeProvidersState {
	providers: PrimeProvider[];
	loaded: boolean;
	error: any;
}

export const primeProvidersInitialState: PrimeProvidersState = {
	providers: [],
	loaded: false,
	error: null,
};

const reducer = createReducer(
	primeProvidersInitialState,
	on(PrimeProviderActions.fetchPrimeProviders, (state) => ({
		...state,
		loaded: false,
		error: null,
	})),
	on(PrimeProviderActions.updatePrimeProviders, (state, { providers }) => ({
		...state,
		loaded: true,
		error: null,
		providers,
	})),
	on(PrimeProviderActions.errorPrimeProviders, (state, { error }) => ({
		...state,
		loaded: true,
		error,
	})),
);

export function primeProvidersReducer(
	state: PrimeProvidersState | undefined,
	action: Action,
) {
	return reducer(state, action);
}
