import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	catchError,
	distinctUntilChanged,
	map,
	switchMap,
	withLatestFrom,
	filter,
	of,
} from 'rxjs';

import * as PrimeProviderActions from './prime-providers.actions';
import { getPrimeProviders } from './prime-providers.selectors';
import { PrimeProvidersService } from '../prime-providers-api.service';

@Injectable()
export class StatePrimeProvidersEffects {
	constructor(
		private readonly actions$: Actions,
		private store: Store,
		private primeProvidersSrv: PrimeProvidersService,
	) {}

	fetchPrimeProviders = createEffect(() =>
		this.actions$.pipe(
			ofType(PrimeProviderActions.fetchPrimeProviders),
			distinctUntilChanged(),
			withLatestFrom(this.store.select(getPrimeProviders)),
			filter(([, providers]) => providers.length === 0),
			switchMap(() => this.primeProvidersSrv.getPrimeProviders()),
			map((response) => {
				return PrimeProviderActions.updatePrimeProviders({ providers: response });
			}),
			catchError((error) => of(PrimeProviderActions.errorPrimeProviders(error))),
		),
	);
}
