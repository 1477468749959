<div class="sm:hidden">
	@if (phoneNumber()) {
		<div class="px-5 py-8 border-b">
			@if (gallery()[0].path) {
				<div class="h-48 mb-4">
					<img
						class="object-cover w-full h-full rounded-lg"
						[src]="gallery()[0].path | cdn"
						[attr.alt]="uniName()"
					/>
				</div>
			}
			<h3 class="mb-2 text-xl font-bold leading-8 text-black">
				Call {{ uniName() }}
			</h3>
			<p class="mb-5 text-sm text-gray-900">
				Get in touch with {{ uniName() }} and learn more. Discover what Clearing
				courses may still be available and secure your spot today!
			</p>
			@if (phoneNumber()) {
				<uc-call-uni-btn
					[uniId]="uniId()"
					[uniName]="uniName()"
					[phoneNumber]="phoneNumber()"
					buttonText="Call University"
					mobileButtonText="Call University"
					componentName="Prime Popup"
					[source]="uniName()"
					sourceType="uni"
					customStyles="w-full"
					[cleSearchOrderPosition]="cleSearchOrderPosition()"
				/>
			}
		</div>
	}
	@if (phoneNumber()) {
		<div class="px-5 py-8 border-b">
			@if (gallery()[1].path) {
				<div class="h-48 mb-4">
					<img
						class="object-cover w-full h-full rounded-lg"
						[src]="gallery()[1].path | cdn"
						[attr.alt]="uniName()"
					/>
				</div>
			}
			<h3 class="mb-2 text-xl font-bold leading-8 text-black">
				Visit {{ uniName() }}'s Website
			</h3>
			<p class="mb-5 text-sm text-gray-900">
				Find out more about {{ uniName() }} here. Find out more about the
				university, its accommodation and Clearing spots.
			</p>
			<uc-clearing-visit-website-btn
				[uniId]="uniId()"
				[externalUrl]="externalUrl()"
				componentName="Prime Popup"
				[source]="uniName()"
				sourceType="uni"
				customStyles="w-full"
				[trackingEnabled]="false"
				[uniName]="uniName()"
				[cleSearchOrderPosition]="cleSearchOrderPosition()"
				[privacyPolicyUrl]="privacyPolicyUrl()"
			/>
		</div>
	}
	<div class="px-5 pt-8 pb-12">
		<h3 class="mb-5 text-xl font-bold leading-8 text-black">
			Learn more about {{ uniName() }}
		</h3>
		<uc-secondary-blue-btn
			(click)="onCloseModal(); onUniProfileClick()"
			buttonText="View Full Profile"
			[link]="'/universities/' + uniSlug() + '/clearing'"
			customStyles="w-full"
		/>
	</div>
</div>
