import { gql } from 'apollo-angular';

export const GET_CLEARING_PRIME_PROVIDERS = gql`
	query getClearingPrimeProviders {
		clearingPrimeProviders {
			id
			name
			short_name
			recommended_percentage
			overview
			cover_image
			logo
			slug
			phone_number
			external_url
			courses_count
			gallery {
				path
				position
			}
			order
		}
	}
`;
