import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-burger-svg',
	standalone: true,
	templateUrl: './burger-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class BurgerSvgComponent {
	customStyles = input('');
}
